import { useContext } from "react";
import TranslationContext from "../store/translation-context";
import Loader from "../components/UI/Loader/Loader";

const Terms = () => {
    const translationCtx = useContext(TranslationContext);

    if(translationCtx.isLoading){
        return <Loader />
    }

    return (
        <>
            <a className="backButton" href="/">Back</a>

            <h1>{translationCtx.getTranslationByKey('termsConditions')}</h1>

            <div className="terms__container">
                <p className="terms_text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce velit justo, fermentum vel mi non, consequat mattis est. Sed id viverra massa. In facilisis odio vitae elit tristique dapibus. Sed pulvinar condimentum metus, id gravida elit vehicula vitae. Pellentesque auctor fringilla mi vel bibendum. Donec consequat sed urna vitae tempus. Aliquam condimentum mauris in maximus ullamcorper. Vestibulum accumsan gravida hendrerit. Etiam non venenatis libero, quis pellentesque nulla. Pellentesque in varius lorem.
                </p>
                <p className="terms_text">
                Cras pretium mi a nisi dictum, at dictum elit aliquet. Maecenas auctor lacus felis, non gravida libero congue viverra. In pulvinar odio sed leo pharetra aliquet. Quisque imperdiet ex lobortis, hendrerit augue eget, faucibus sem. Mauris laoreet velit massa, eu gravida felis interdum id. Fusce fermentum vitae diam id pharetra. Mauris orci eros, sodales sit amet tortor ac, cursus volutpat lorem. Sed tempus auctor suscipit. Donec suscipit non quam in mattis. Donec dictum ornare nibh, id auctor nibh pretium eget. Aliquam et quam et neque tempor efficitur nec tempor ligula. Suspendisse sit amet ultricies ipsum. Proin in nisi bibendum, varius orci non, interdum felis.
                </p>
                <p className="terms_text">
                Pellentesque at eros ut felis ullamcorper venenatis. Morbi enim erat, malesuada sit amet scelerisque at, feugiat eu urna. Aliquam convallis condimentum leo, et facilisis nulla maximus at. Maecenas tempus, arcu ut tempus lacinia, mi dui faucibus libero, vitae efficitur felis ligula sed nisi. Cras sollicitudin mi sed turpis consequat tincidunt. Pellentesque sodales dignissim nisi nec ultricies. Donec euismod fringilla elit, vel luctus odio lacinia at. Quisque rhoncus nisl at mi hendrerit, ac vulputate justo eleifend. Proin rhoncus erat et nulla scelerisque, vitae venenatis sapien pellentesque. Etiam pretium viverra felis in dictum. Pellentesque massa sem, consectetur nec tincidunt hendrerit, rhoncus non elit. Maecenas id nunc a nibh interdum ultrices et eu risus.
                </p>
                <p className="terms_text">
                Morbi nibh enim, pharetra eu lectus vitae, semper condimentum leo. Phasellus et commodo arcu, ut sollicitudin quam. Aliquam sed lobortis arcu, eu scelerisque magna. Suspendisse hendrerit massa urna, et semper orci tempor ut. In sit amet rutrum ante. Etiam sagittis leo venenatis elit facilisis, sed finibus lectus tempor. Cras euismod lacus in sapien consequat, sit amet accumsan justo semper. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nunc aliquet dictum ultrices.
                </p>
                <p className="terms_text">
                Praesent sagittis, sapien eu ultrices convallis, felis nulla imperdiet libero, et consequat massa leo in lorem. Maecenas tristique iaculis massa non sagittis. Duis maximus tortor vitae dolor imperdiet, sed pharetra justo tempus. Donec vel sapien neque. Aliquam pretium ex a dolor mollis facilisis. Donec lacinia risus eget felis aliquet venenatis. Maecenas non mattis justo, eu mattis quam. Proin blandit tincidunt nibh ac convallis.
                </p>
            </div>
        </>
    )
}

export default Terms;