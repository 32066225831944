import { useContext } from "react"
import TranslationContext from "../store/translation-context"

export default function Footer() {
    const translationCtx = useContext(TranslationContext);

    if(translationCtx.isLoading){
        return <></>;
    }

    return (
        <footer className={`app__footer ${(translationCtx.hasError !== undefined) ? 'no-height' : ''}`}>
            <p className="orangeText mb-24">
                {translationCtx.getTranslationByKey('tehnicalOrg')}
            </p>
            <p className="disclaimer__center">
                {translationCtx.getTranslationByKey('tehnicalDesc1')}
            </p>
            <p className="disclaimer__center mb-24">
                {translationCtx.getTranslationByKey('tehnicalDesc2')}
            </p>
            <p className="disclaimer__center">
                {translationCtx.getTranslationByKey('tehnicalDesc3')}
            </p>
            <p className="disclaimer__center mb-24">
                Tel: <a href="tel:+387 33 863 666">+387 33 863 666</a>
            </p>
            <p className="disclaimer__center">
                E-mail: <a href="mailto:info@purplekey.ba">info@purplekey.ba</a>
            </p>
        </footer>
    )
}