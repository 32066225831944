import { useContext, useEffect, useState } from "react";

import './registration.css';
import Input from "../UI/Input/Input.jsx";
import Loader from "../UI/Loader/Loader.jsx";
import TranslationContext from "../../store/translation-context";
import Payment from "../Payment/Payment.jsx";

const earlyRegistrationExpirationDate = "03/01/2024";

export default function Registration() {

    const translationCtx = useContext(TranslationContext);

    const [hasAccompanyingPerson, setHasAccompanyingPerson] = useState(false);
    const [hasDedicatedInvoicePerson, setHasDedicatedInvoicePerson] = useState(false);
    const [registrationAvailable, setRegistrationAvailable] = useState(false);

    const [registrationPrice, setRegistrationPrice] = useState(0);
    const [accommodationPrice, setAccommodationPrice] = useState(0);
    const [extrasPrice, setExtrasPrice] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [earlyRegistrationOpen, setIsEarlyRegistrationOpen] = useState(true);

    const [registrationFeeType, setRegistrationFeeType] = useState(0);


    const toggleAccompanyingPerson = (event) => {
        const selection = event.target.value;
        const val = selection === 'true';        
        setHasAccompanyingPerson(val);
    }

    const toggleInvoicePerson = (event) => {
        const selection = event.target.value;
        const val = selection === 'true';        
        setHasDedicatedInvoicePerson(val);
    }


    const handleFormSubmit = (event) => {
        event.preventDefault();
        const form = new FormData(event.target);
        const formData = Object.fromEntries(form.entries());

        console.log(formData);
    }

    useEffect(() => {
        const checkIfRegistrationIsAvailable = async () => {
            const req = await fetch(`${process.env.REACT_APP_API_URL}/registrationopen`);
            const status = req.status;
            if(status === 200){
                setRegistrationAvailable(true)
            }
            if(status === 400){
                setRegistrationAvailable(false)
            }
        }


        const date = new Date();
        const expiryDate = new Date(earlyRegistrationExpirationDate);
        if(date.setHours(0,0,0,0) === expiryDate.setHours(0,0,0,0)){
            setIsEarlyRegistrationOpen(false);
        }

        checkIfRegistrationIsAvailable();
    }, [])



    
    if(translationCtx.isLoading){
        return <Loader />
    }

    if(!registrationAvailable || translationCtx.hasError){
        return <>
            <h2 className="mt">Registration is currently unavailable.</h2>
            <h2>For any questions, please contact <a href="mailto:info@purplekey.ba">info@purplekey.ba</a></h2>
            <h2 className="mb">Or, <a href="tel:+387 33 863 666">+387 33 863 666</a></h2>
        </>
    }

    return (
        <>
            <h1>{translationCtx.getTranslationByKey('registration')}</h1>

            <Payment />

            <form onSubmit={handleFormSubmit} className="registration__form">

                <div className="form__section">

                    <p className="form__text bold mb-104">
                        {translationCtx.getTranslationByKey('early_notice')}
                    </p>

                    <div className="form__section__header">
                        <p className="num">1.</p>
                        <p className="text uppercase">{translationCtx.getTranslationByKey('personalInfo')}</p>
                    </div>

                    <div className="form__row">
                        <Input
                            name="first_name"
                            label={translationCtx.getTranslationByKey('firstname')}
                            id="first_name_input"
                            type="text"
                            required="required"
                            autoComplete='first name'/>

                        <Input
                            name="last_name"
                            label={translationCtx.getTranslationByKey('lastname')}
                            id="last_name_input"
                            type="text"
                            required="required"
                            autoComplete='last name'/>

                        <Input
                            name="title"
                            label={translationCtx.getTranslationByKey('title')}
                            id="title_input"
                            type="text"
                            required="required"/>
                    </div>

                    <div className="form__row">
                        <Input
                            name="organisation"
                            label={translationCtx.getTranslationByKey('organisation')}
                            id="organisation_input"
                            type="text"
                            required="required"/>

                        <Input
                            name="phone"
                            label={translationCtx.getTranslationByKey('phone')}
                            id="phone_input"
                            type="text"
                            required="required"
                            autoComplete='phone'/>

                        <Input
                            name="email"
                            label={translationCtx.getTranslationByKey('email')}
                            id="email_input"
                            type="email"
                            required="required"
                            autoComplete='email'/>
                    </div>

                    <div className="form__row">
                        <Input
                            name="city"
                            label={translationCtx.getTranslationByKey('city')}
                            id="city_input"
                            type="text"
                            required="required"/>

                        <Input
                            name="country"
                            label={translationCtx.getTranslationByKey('country')}
                            id="country_input"
                            type="text"
                            required="required"
                            autoComplete='country'/>

                        <Input
                            name="address"
                            label={translationCtx.getTranslationByKey('address')}
                            id="address_input"
                            type="text"
                            required="required"
                            autoComplete='address'/>
                    </div>

                </div>



                <div className="form__section">

                    <div className="form__section__header">
                        <p className="num">2.</p>
                        <p className="text uppercase">{translationCtx.getTranslationByKey('registrationFee')}</p>
                    </div>

                    <p className="form__text bold mb-24">
                        {translationCtx.getTranslationByKey('registrationFeeDesc')}
                    </p>

                    <p className="form__text mb-24">
                        {translationCtx.getTranslationByKey('registrationFeeDescInstr')}
                    </p>

                    <div className="form__column">

                        <Input type="radio" 
                            name="registration_fee" 
                            id="physicans_input" 
                            label={translationCtx.getTranslationByKey('registrationFeeOption1')}
                            required="required" 
                            onChangeHandler={() => setRegistrationFeeType(1)}/>

                        <Input type="radio" 
                            name="registration_fee" 
                            id="young_physicans_input" 
                            label={translationCtx.getTranslationByKey('registrationFeeOption2')}
                            required="required"
                            onChangeHandler={() => setRegistrationFeeType(2)}/>

                        <Input type="radio" 
                            name="registration_fee" 
                            id="exhibitor_input"
                            label={translationCtx.getTranslationByKey('registrationFeeOption3')}
                            required="required"
                            onChangeHandler={() => setRegistrationFeeType(3)}/>

                        <Input type="radio" 
                            name="registration_fee"
                            id="student_input" 
                            label={translationCtx.getTranslationByKey('registrationFeeOption4')}
                            required="required"
                            onChangeHandler={() => setRegistrationFeeType(4)}/>

                    </div>

                </div>



                <div className="form__section">

                    <div className="form__section__header">
                        <p className="num">3.</p>
                        <p className="text uppercase">{translationCtx.getTranslationByKey('accompanyingPersonRegOptions')}</p>
                    </div>


                    <div className="form__column">
                        <Input 
                            type="radio"
                            id="yes_accomp_person_input"
                            value="true"
                            name="accomp_person"
                            label={translationCtx.getTranslationByKey('accompanyingPersonRegOption1')}
                            onChangeHandler={toggleAccompanyingPerson} 
                        />
                        
                        {hasAccompanyingPerson &&
                        
                            <div className="form__column accompanying__person_details">

                                <div className="form__row">
                                    <Input
                                        name="accomp_full_name"
                                        label={translationCtx.getTranslationByKey('firstAndLastName')}
                                        id="accomp_full_name_input"
                                        type="text"
                                        required="required"/>

                                    <Input
                                        name="accomp_email"
                                        label={translationCtx.getTranslationByKey('email')}
                                        id="email_input"
                                        type="email"
                                        required="required"/>
                                </div>

                                <div className="form__row">
                                    <Input
                                        name="accomp_title"
                                        label={translationCtx.getTranslationByKey('title')}
                                        id="accomp_title_input" 
                                        type="text"
                                        required="required"/>

                                    <Input 
                                        name="accomp_title"
                                        label={translationCtx.getTranslationByKey('organisation')}
                                        id="accomp_title_input"
                                        type="text"
                                        required="required"/>

                                </div>

                            </div>
                        
                        }

                        <Input 
                            type="radio" 
                            id="no_accomp_person"
                            value="false"
                            name="accomp_person"
                            label={translationCtx.getTranslationByKey('accompanyingPersonRegOption2')}
                            onChangeHandler={toggleAccompanyingPerson}
                        />
                    </div>

                </div>



                <div className="form__section">

                    <div className="form__section__header">
                        <p className="num">4.</p>
                        <p className="text uppercase">{translationCtx.getTranslationByKey('accommodation')}:</p>
                    </div>

                    <p className="form__text bold mb-24">
                        {translationCtx.getTranslationByKey('accommodationDesc')}
                    </p>

                    <p className="form__text mb-24">
                        {translationCtx.getTranslationByKey('accommodationDescInstr')}
                    </p>

                    <div className="form__column">
                        <Input
                            type="radio"
                            name="accomodation"
                            id="merriot_courtyard_input"
                            label={translationCtx.getTranslationByKey('accommodationOption1')}
                            required="required"/>

                        <Input
                            type="radio"
                            name="accomodation"
                            id="holliday_hotel_input"
                            label={translationCtx.getTranslationByKey('accommodationOption2')}
                            required="required" />

                        <Input
                            type="radio"
                            name="accomodation"
                            id="no_accommodation_input"
                            label={translationCtx.getTranslationByKey('accommodationOption3')}
                            required="required" />
                    </div>

                </div>



                <div className="form__section">

                    <div className="form__section__header">
                        <p className="num">5.</p>
                        <p className="text uppercase">{translationCtx.getTranslationByKey('payment')}:</p>
                    </div>

                    <div className="form__column">
                        <Input
                            type="radio"
                            name="payment_type"
                            id="credit_card_input"
                            label={translationCtx.getTranslationByKey('paymentOption1')}
                            required="required"/>

                        <Input
                            type="radio"
                            name="payment_type"
                            id="bank_transfer_input"
                            label={translationCtx.getTranslationByKey('paymentOption2')}
                            required="required" />
                    </div>

                </div>



                <div className="form__section">

                    <div className="form__section__header">
                        <p className="num">6.</p>
                        <p className="text uppercase">{translationCtx.getTranslationByKey('paymentDetails')}:</p>
                    </div>

                    <p className="form__text mb-12">
                        {translationCtx.getTranslationByKey('paymentDetailsText1')}
                    </p>

                    <p className="form__text mb-12">
                        {translationCtx.getTranslationByKey('paymentDetailsText2')}
                    </p>

                    <p className="form__text mb-12">
                        {translationCtx.getTranslationByKey('paymentDetailsText3')}
                    </p>

                    <p className="form__text mb-12">
                        {translationCtx.getTranslationByKey('paymentDetailsText4')}
                    </p>


                </div>



                <div className="form__section">

                    <div className="form__section__header">
                        <p className="num">7.</p>
                        <p className="text uppercase">{translationCtx.getTranslationByKey('issueInvoiceTo')}:</p>
                    </div>

                    <div className="form__column">
                        <Input 
                            type="radio"
                            id="no_dedicated_input"
                            value="false"
                            name="dedicated_invoiced_person"
                            label={translationCtx.getTranslationByKey('issueInvoiceToOption1')}
                            onChangeHandler={toggleInvoicePerson} 
                        />

                        <Input 
                            type="radio" 
                            id="yes_dedicated_input"
                            value="true"
                            name="dedicated_invoiced_person"
                            label={translationCtx.getTranslationByKey('issueInvoiceToOption2')}
                            onChangeHandler={toggleInvoicePerson}
                        />
                        
                        {hasDedicatedInvoicePerson &&
                        
                            <div className="form__column accompanying__person_details">

                                <div className="form__row">
                                    <Input
                                        name="invoice_person_full_name"
                                        label={translationCtx.getTranslationByKey('firstAndLastName')}
                                        id="invoice_person_full_name_input"
                                        type="text"
                                        required="required"/>

                                    <Input
                                        name="invoice_person_company_name"
                                        label={translationCtx.getTranslationByKey('companyName')}
                                        id="invoice_person_company_name_input"
                                        type="text"
                                        required="required"/>
                                </div>

                                <div className="form__row">
                                    <Input
                                        name="invoice_person_vat"
                                        label={translationCtx.getTranslationByKey('vattax')}
                                        id="invoice_person_vat_input"
                                        type="text"
                                        required="required"/>

                                    <Input
                                        name="invoice_person_street"
                                        label={translationCtx.getTranslationByKey('streetnamenum')}
                                        id="invoice_person_street_input"
                                        type="text"
                                        required="required"/>
                                </div>

                                <div className="form__row">
                                    <Input
                                        name="invoice_person_postalcity"
                                        label={translationCtx.getTranslationByKey('postalcodecity')}
                                        id="invoice_person_postalcity_input"
                                        type="text"
                                        required="required"/>

                                    <Input
                                        name="invoice_person_country"
                                        label={translationCtx.getTranslationByKey('country')}
                                        id="invoice_person_country_input"
                                        type="text"
                                        required="required"/>
                                </div>

                            </div>
                        
                        }

                    </div>


                </div>



                <div className="form__section">

                    <div className="form__section__header">
                        <p className="num">8.</p>
                        <p className="text uppercase">TOTAL:</p>
                    </div>

                    <div className="form__column">

                        <div className="price__item">
                            <p>{translationCtx.getTranslationByKey('registration')}:</p>
                            <div className="price">
                                <p>{(registrationPrice !== 0) && (registrationPrice)}</p>
                            </div>
                        </div>

                        <div className="price__item">
                            <p>{translationCtx.getTranslationByKey('accommodation')}:</p>
                            <div className="price">
                                <p>{(accommodationPrice !== 0) && (accommodationPrice)}</p>
                            </div>
                        </div>

                        <div className="price__item">
                            <p>EXTRAS:</p>
                            <div className="price">
                                <p>{(extrasPrice !== 0) && (extrasPrice)}</p>
                            </div>
                        </div>

                        <div className="price__item total">
                            <p>TOTAL:</p>
                            <div className="price">
                                <p>{(totalPrice !== 0) && (totalPrice)}</p>
                            </div>
                        </div>

                    </div>

                </div>

                <div className="form__section">

                    <div className="form__section__header">
                        <p className="num">9.</p>
                        <p className="text uppercase">{translationCtx.getTranslationByKey('submitRegistration')}:</p>
                    </div>

                    <Input
                        type="radio"
                        id="terms_agreement_input"
                        value="false"
                        name="terms_agreement"
                        label={translationCtx.getTranslationByKey('submitText')}
                        link="terms"
                        linkText={translationCtx.getTranslationByKey('submitTextLink')}
                        onChangeHandler={toggleInvoicePerson} 
                        required="required"
                    />
                
                </div>

                <button className="submit__form">{translationCtx.getTranslationByKey('submitRegistration')}</button>
            </form>
            
        </>
    )
}