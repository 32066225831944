import './App.css';
import './fonts.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Layout from './pages/Layout';
import Register from './pages/Register';
import Terms from './pages/Terms';
import NotFound from './pages/NotFound';
import { TranslationContextProvider } from './store/translation-context';

export default function App() {
  return (
    <TranslationContextProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Register />} path="/" />
            <Route path="terms" element={<Terms />} />
            <Route path='*' element={<NotFound />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </TranslationContextProvider>
  );
}