import { useEffect, useRef } from 'react';
import { encode as base64_encode} from 'base-64';
import CryptoJS from 'crypto-js'

import './payment.css';

let paymentJs;

export default function Payment() {

    const cardHolder = useRef('');
    const month = useRef('');
    const year = useRef('');
    const email = useRef('');
    const transactionToken = useRef(null);

    const initPaymentJs = () => {
        paymentJs = new window.PaymentJs();

        paymentJs.init(process.env.REACT_APP_PJS_SECRET, 'number_div', 'cvv_div', function (payment) {
            var numberFocused = false;
            var cvvFocused = false;
            var style = {
                'border': '3px solid gray',
            };
            var hoverStyle = {
                'border': '3px solid blue',
            };
            var focusStyle = {
                'border': '3px solid green',
            };

            // Set the initial style
            payment.setNumberStyle(style);
            payment.setCvvStyle(style);

            // Focus events
            payment.numberOn('focus', function () {
                numberFocused = true;
                payment.setNumberStyle(focusStyle);
            });
            payment.cvvOn('focus', function () {
                cvvFocused = true;
                payment.setCvvStyle(focusStyle);
            });

            // Blur events
            payment.numberOn('blur', function () {
                numberFocused = false;
                payment.setNumberStyle(style);
            });
            payment.cvvOn('blur', function () {
                cvvFocused = false;
                payment.setCvvStyle(style);
            });

            // Hover events
            payment.numberOn('mouseover', function () {
                // Don't override style if element is already focused
                if (!numberFocused) {
                payment.setNumberStyle(hoverStyle);
                }
            });
            payment.numberOn('mouseout', function () {
                // Don't override style if element is already focused
                if (!numberFocused) {
                payment.setNumberStyle(style);
                }
            });
            payment.cvvOn('mouseover', function () {
                // Don't override style if element is already focused
                if (!cvvFocused) {
                payment.setCvvStyle(hoverStyle);
                }
            });
            payment.cvvOn('mouseout', function () {
                // Don't override style if element is already focused
                if (!cvvFocused) {
                payment.setCvvStyle(style);
                }
            });
        });
    }

    const interceptSubmit = (e) => {
        e.preventDefault();

        if(!paymentJs || !paymentJs.initialized){
            initPaymentJs()
            // return alert('Paymentjs not init');
        }



        var data = {
            card_holder: cardHolder.current.value,
            month: month.current.value,
            year: year.current.value,
            email: email.current.value
        };
        paymentJs.tokenize(
            data, //additional data, MUST include card_holder (or first_name & last_name), month and year
            function (token, cardData) { //success callback function
                
                const transId = 'nefrobih-' + Date.now()

                const paymentBody = {
                    "merchantTransactionId": transId,
                    // "additionalId1": "x0001",
                    // "additionalId2": "y0001",
                    // "extraData": {
                    //   "someKey": "someValue",
                    //   "otherKey": "otherValue"
                    // },
                    // "merchantMetaData": "merchantRelevantData",
                    "amount": "2.00",
                    // "surchargeAmount": "0.9",
                    "currency": "EUR",
                    // "successUrl": "https://example.com/success",
                    // "cancelUrl": "https://example.com/cancel",
                    // "errorUrl": "https://example.com/error",
                    // "callbackUrl": "https://example.com/callback",
                    "transactionToken": token,
                    "description": "nefrobih attendee fee",
                    // "customer": {
                    //   "identification": "c0001",
                    //   "firstName": "John",
                    //   "lastName": "Doe",
                    //   "birthDate": "1990-10-10",
                    //   "gender": "M",
                    //   "billingAddress1": "Maple Street 1",
                    //   "billingAddress2": "Syrup Street 2",
                    //   "billingCity": "Victoria",
                    //   "billingPostcode": "V8W",
                    //   "billingState": "British Columbia",
                    //   "billingCountry": "CA",
                    //   "billingPhone": "1234567890",
                    //   "shippingFirstName": "John",
                    //   "shippingLastName": "Doe",
                    //   "shippingCompany": "Big Company Inc.",
                    //   "shippingAddress1": "Yellow alley 3",
                    //   "shippingAddress2": "Yellow alley 4",
                    //   "shippingCity": "Victoria",
                    //   "shippingPostcode": "V8W",
                    //   "shippingState": "British Columbia",
                    //   "shippingCountry": "CA",
                    //   "shippingPhone": "1234567890",
                    //   "company": "John's Maple Syrup",
                    //   "email": "john@example.com",
                    //   "emailVerified": false,
                    //   "ipAddress": "127.0.0.1",
                    //   "nationalId": "123123",
                    //   "extraData": {
                    //     "someCustomerDataKey": "value",
                    //     "anotherKey": "anotherValue"
                    //   },
                    //   "paymentData": {
                    //     "ibanData": {
                    //       "iban": "AT123456789012345678",
                    //       "bic": "ABC",
                    //       "mandateId": "1234",
                    //       "mandateDate": "2019-09-29"
                    //     }
                    //   }
                    // },
                    // "threeDSecureData": {
                    //   "3dsecure": "MANDATORY"
                    // },
                    "language": "en"
                }

                const inputBody = JSON.stringify(paymentBody);

                const authHeaderString = base64_encode(`${process.env.REACT_APP_GATEWAY_USERNAME}:${process.env.REACT_APP_GATEWAY_PASSWORD}`)
                

                const apiKey = process.env.REACT_APP_GATEWAY_APIKEY;
                const secretKey = process.env.REACT_APP_GATEWAY_SECRETKEY;

                const keys = {
                    'akey' : apiKey,
                    'skey' : secretKey
                }

                const xSignature = CryptoJS.HmacSHA512(inputBody, keys['skey']).toString(CryptoJS.enc.Hex);

                async function sendTransaction () {
                    await fetch(`${process.env.REACT_APP_API_URL}/transaction/send`, {
                    // await fetch(`https://gateway.bankart.si/api/v3/transaction/${apiKey}/debit`, {
                        method: 'POST',
                        body: inputBody,
                        // credentials: 'include',
                        headers: {
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Origin': process.env.REACT_APP_BASEURL,
                            'Accept': 'application/json',
                            'Date': Date.toString(),
                            'Authorization': 'Basic '+ authHeaderString,
                            'X-Signature': xSignature
                        }
                    }).then(function (res) {
                        // return res.json();
                        console.log(res);
                    }).then(function (body) {
                        console.log(body);
                    }).catch((error) => {
                        console.log(error);
                    });
                }

                sendTransaction();
            },
            function (errors) { //error callback function
                console.log(errors);
                return alert('Errors occurred');
                //render error information here
            }
        );
    }

    useEffect(() => {

        if(window.PaymentJs){
            initPaymentJs();
        }
    })


    return (
        <div className="container">
            <form id="payment_form" onSubmit={(e) => interceptSubmit(e)}>

                <input type="hidden" name="transaction_token" ref={transactionToken} id="transaction_token"/>
                <div>
                    <label htmlFor="card_holder">Card holder</label>
                    <input type="text" id="card_holder" ref={cardHolder} name="card_holder"/>
                </div>
                <div>
                    <label htmlFor="number_div">Card number</label>
                    <div id="number_div" style={{height: '35px', width: '200px'}}></div>
                </div>
                <div>
                    <label htmlFor="cvv_div">CVV</label>
                    <div id="cvv_div" style={{height: '35px', width: '200px'}}></div>
                </div>
                <div>
                    <label htmlFor="exp_month">Month</label>
                    <input type="text" ref={month} id="exp_month" name="exp_month"/>
                </div>
                <div>
                    <label htmlFor="exp_year">Year</label>
                    <input type="text" ref={year} id="exp_year" name="exp_year"/>
                </div>
                <div>
                    <label htmlFor="email">Email</label>
                    <input type="text" ref={email} id="email" name="email"/>
                </div>
                <div>
                    <input type="submit" value="Submit"/>
                </div>
            </form>
        </div>
    )
}