import './input.css';

export default function Input({ label, id,  onChangeHandler = null, link = null, linkText = null, ...props }) {

    const wrapperClass = 'input__wrap ' + props.type;

    if(props.type === 'radio'){
        return (
            <div className={wrapperClass}>
                <label htmlFor={id}>
                    <input id={id} value={id} {...props} onChange={onChangeHandler} />
                    <span>{label}{(link && linkText) && (<a href={link}>{linkText}</a>)}</span>
                </label>
                
            </div>
        )
    }

    return (
        <div className={wrapperClass}>
            <label htmlFor={id}>{label}</label>
            <input id={id} {...props} />
        </div>
    )
}