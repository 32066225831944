import { useContext, useEffect, useState } from 'react';
import bs from '../../bs.png';
import en from '../../en.png';
import TranslationContext from '../../store/translation-context';

import styles from './localeswitcher.module.css';

export default function LocaleSwitcher(){

    const translationCtx = useContext(TranslationContext);

    function changeLanguageHandler (lang) {
        translationCtx.onChangeLanguage(lang);
    }

    if(translationCtx.hasError !== undefined){
        return <></>;
    }

    var flagWithAction = null;
    if(translationCtx.currentLanguage === 'en'){
        flagWithAction = (
            <img src={en} onClick={() => changeLanguageHandler('bs')} />
        )
    }
    if(translationCtx.currentLanguage === 'bs'){
        flagWithAction = (
            <img src={bs} onClick={() => changeLanguageHandler('en')} />
        )
    }
    
    
    return (
        <div className={styles.localeswitcher__wrapper}>{flagWithAction}</div>
    )
    
}