import React, { useCallback, useEffect, useState } from "react";

const TranslationContext = React.createContext({
    currentLanguage: 'en',
    isLoading: true,
    hasError: false,
    onChangeLanguage: () => {},
    getTranslationByKey: () => {}
});

const translationsURL = `${process.env.REACT_APP_API_URL}/translation/`;

async function fetchTranslationData (language) {
    const response = await fetch(translationsURL + language, {
        headers: {
            "Content-Type": "application/json",
            // "Access-Control-Allow-Origin": "https://register.nefrobih.ba"
            "Access-Control-Allow-Origin": `${process.env.REACT_APP_BASEURL}`
        },
    })
    const resData = await response.json()

    if(!response.ok){
        throw new Error(resData.message || 'Something went wrong, failed to send request.');
    }

    return resData;
}

export const TranslationContextProvider = (props) => {
    const [language, setLanguage] = useState('en')
    const [data, setData] = useState([]); 
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState();


    const getTranslations = useCallback(
        async function sendRequestToFetchData(language){
            setIsLoading(true);
            try{
                const result = await fetchTranslationData(language)
                setData(result);
            } catch (error) {
                setError(error.message || 'Something went wrong.');
            }
            setIsLoading(false);
        }, 
        []
    )

    const getTranslationByKey = (key) => {
        return data[key];
    }


    const onChangeLanguageHandler = (lang) => {
        setLanguage(lang);
    }


    useEffect(() => {
        getTranslations(language)
    }, [getTranslations, language])

    return <TranslationContext.Provider
        value={{
            currentLanguage: language,
            isLoading,
            hasError: error,
            onChangeLanguage: onChangeLanguageHandler,
            getTranslationByKey: getTranslationByKey
        }}>
            {props.children}
        </TranslationContext.Provider>
}

export default TranslationContext;