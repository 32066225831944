import Logo from './../logo.png';
import LocaleSwitcher from './LocaleSwitcher/LocaleSwitcher';

export default function Header() {

    return (
        <div className='app__header'>
            <img src={Logo} alt='logo' />
            <LocaleSwitcher />
        </div>
    )
}